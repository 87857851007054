<template>
  <div>
    <b-row>
      <b-col>
        <b-alert variant="success" class="text-center" show>
          Tvoja prihláška bola úspešne odoslaná a prijatá.
        </b-alert>
        <p>
          Pozn.: Ak by bolo potrebné zmeniť údaje v prihláške, kontaktuj nás
          emailom alebo telefonicky. Kontaktné údaje nájdeš vyššie, v popise
          sústredenia.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h1>Informácie k platbe:</h1>
      </b-col>
    </b-row>
    <b-row
      v-if="
        attendance.payment.discount_request &&
        attendance.payment.discount_processed === false
      "
    >
      <b-col cols="12" class="mt-2">
        <b-alert variant="warning" class="text-center" show>
          Tvoja žiadosť o príspevok z fondu pre podporu riešiteľov bola
          zaznamenaná. Zatiaľ nič neplať, žiadosť posúdime a ozveme sa ti
          osobne.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-else-if="fullyPaid">
      <b-col>
        <b-alert variant="success" class="text-center" show>
          Tvoja platba bola prijatá, ďakujeme a tešíme sa na teba na sústredení.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col v-if="attendance.payment.discount_processed === true" cols="12">
        <b-alert variant="success" class="text-center" show>
          Tvoja žiadosť o príspevok z fondu pre podporu riešiteľov bola prijatá.
          Nižšie uvedená cena už zahŕňa uznanú zľavu.
        </b-alert>
      </b-col>
      <b-col md="6" xl="4" class="text-center mt-2">
        <div class="pay-by-square-border">
          <LoadingSpinner :is-loaded="loaded">
            <div
              class="pay-by-square-code"
              :style="{ backgroundImage: `url(${payBySquare})` }"
            />
          </LoadingSpinner>
        </div>
      </b-col>
      <b-col class="d-flex align-items-center m-2">
        <article>
          <ul>
            <li>
              Suma: {{ attendance.payment.price }} €
              <span v-if="attendance.payment.paid !== '0.00'">
                (z toho je už {{ attendance.payment.paid }} € zaplatené)
              </span>
            </li>
            <li>
              Číslo účtu: <b>{{ ibanStart }}</b> {{ ibanMiddle
              }}<b>{{ ibanEnd }}</b>
            </li>
            <li>SWIFT kód: {{ attendance.payment.swift }}</li>
            <li>Variabilný symbol: {{ attendance.payment.vs }}</li>
            <li>Špecifický symbol: {{ attendance.payment.ss }}</li>
          </ul>
        </article>
      </b-col>
      <b-col class="mt-2" cols="12">
        <div>
          Účastnícky poplatok je hradený prevodom na účet. Stav platby si môžeš
          následne skontrolovať na tejto stránke - po prijatí ti tu namiesto
          informácií o platbe zobrazíme potvrdenie.
        </div>
        <div>
          Pozn.: Prijaté platby aktualizujeme približne raz za 48 hodín, môže
          preto chvíľu trvať, kým tvoju platbu zaznamenáme.
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { apiEvents, LoadingSpinner } from "frontend-common";

export default {
  name: "EventAttendanceDetail",
  components: {
    LoadingSpinner,
  },
  mixins: [apiEvents],
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payBySquare: "",
      loaded: false,
    };
  },
  computed: {
    fullyPaid() {
      return (
        Number(this.attendance.paid) >= Number(this.attendance.price) - 1e-4
      );
    },
    ibanStart() {
      return (
        this.attendance.payment.iban.substr(0, 4) +
        " " +
        this.attendance.payment.iban.substr(4, 4)
      );
    },
    ibanMiddle() {
      const remaining = this.attendance.payment.iban.substr(8);
      let out = "";
      for (let i = 0; i < remaining.length; i++) {
        if (remaining[i] !== "0") {
          break;
        }
        out += remaining[i];
        if (i % 4 === 3) {
          out += " ";
        }
      }
      return out;
    },
    ibanEnd() {
      const remaining = this.attendance.payment.iban.substr(8);
      let out = "";
      let foundNonZero = false;
      for (let i = 0; i < remaining.length; i++) {
        if (remaining[i] === "0" && !foundNonZero) {
          continue;
        }
        foundNonZero = true;
        out += remaining[i];
        if (i % 4 === 3) {
          out += " ";
        }
      }
      return out;
    },
  },
  mounted() {
    this.apiPayBySquare(this.eventId).then((data) => {
      this.payBySquare = data;
      this.loaded = true;
    });
  },
  methods: {},
};
</script>

<style lang="scss">
.pay-by-square-border {
  display: inline-block;
  width: 250px;
  height: 250px;
  background-size: 100%;
  position: relative;
  background: url(/pbs-border.svg) no-repeat center;

  div {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .pay-by-square-code {
    background-size: 75%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 12px;
  }
}
</style>
