<template>
  <article>
    <h1>{{ event.name }}</h1>
    <div>
      <p><b>Termín:</b> {{ duration }}</p>
      <p><b>Prihlasovanie:</b> {{ registrationDuration }}</p>
    </div>
    <div>
      <vue-markdown :source="event.description" />
    </div>

    <b-card v-if="event.site" header="Miesto konania" :title="event.site.name">
      <b-card-text>
        <b-row>
          <b-col md="6">
            <ul>
              <li>
                Adresa:
                {{ event.site.address.pretty }}
              </li>
              <li>
                Stránka:
                <a :href="event.site.website" target="_blank">{{
                  event.site.website
                }}</a>
              </li>
            </ul>
            <vue-markdown :source="event.site.description" />
          </b-col>
          <b-col v-if="event.site.lon && event.site.lat" md="6">
            <iframe
              style="border: none; width: 100%"
              allowfullscreen
              height="350"
              :src="mapUrl"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <p v-else class="text-muted">Miesto konania ešte nie je uvedené!</p>
  </article>
</template>

<script>
import { utils } from "frontend-common";
import constants from "@/constants";
import VueMarkdown from "vue-markdown";

export default {
  name: "EventInformation",
  components: {
    VueMarkdown,
  },
  mixins: [],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    duration() {
      return utils.datesToDuration(this.event.start_date, this.event.end_date);
    },
    registrationDuration() {
      return utils.datesToDuration(
        this.event.registration_start_date,
        this.event.registration_end_date,
      );
    },
    mapUrl() {
      return (
        "https://www.google.com/maps/embed/v1/place?key=" +
        constants.googleMapsKey +
        "&q=" +
        this.event.site.lat +
        "," +
        this.event.site.lon
      );
    },
  },
  mounted() {},
  methods: {},
};
</script>
