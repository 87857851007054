<template>
  <b-alert variant="success" class="mt-5" show>
    <p>
      Prihlášku na toto sústredenie si odmietol/odmietla. Ak si si to
      rozmyslel/-a, alebo sa ti zmenili plány, môžeš ju opätovne prijať.
    </p>

    <b-button variant="success" :disabled="working" @click="submit">
      <LoadingSpinner :is-loaded="!working" small>
        Prijať prihlášku
      </LoadingSpinner>
    </b-button>
  </b-alert>
</template>

<script>
import { apiEvents } from "frontend-common";

export default {
  name: "EventUndecline",
  mixins: [apiEvents],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      working: false,
    };
  },
  methods: {
    async submit() {
      this.working = true;

      this.apiUndeclineEvent(this.event.id)
        .then(async () => {
          this.$root.successToast("Prihláška bola úspešne aktualizovaná.");
          await this.$root.loadStatus();
          this.$emit("updated");
        })
        .catch(() => {
          this.$root.dangerToast("Nastala chyba. Skús to znovu neskôr.");
        })
        .finally(() => (this.working = false));
    },
  },
};
</script>

<style scoped></style>
